
      import API from "!../../.yarn/__virtual__/style-loader-virtual-8dce902e66/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../.yarn/__virtual__/style-loader-virtual-8dce902e66/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../.yarn/__virtual__/style-loader-virtual-8dce902e66/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../.yarn/__virtual__/style-loader-virtual-8dce902e66/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../.yarn/__virtual__/style-loader-virtual-8dce902e66/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../.yarn/__virtual__/style-loader-virtual-8dce902e66/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../.yarn/__virtual__/css-loader-virtual-17f6b9cf9b/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../.yarn/cache/resolve-url-loader-npm-5.0.0-9b441067c9-fb013845b4.zip/node_modules/resolve-url-loader/index.js!../../.yarn/__virtual__/postcss-loader-virtual-e7d8d6af87/0/cache/postcss-loader-npm-8.1.1-20cf547c92-7ae38e6351.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[3]!../../.yarn/__virtual__/sass-loader-virtual-3bd3db2d6c/0/cache/sass-loader-npm-16.0.3-70aa568612-cd49635013.zip/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].use[4]!./fonts.scss";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = insertFn.bind(null, "head");
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../.yarn/__virtual__/css-loader-virtual-17f6b9cf9b/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../.yarn/cache/resolve-url-loader-npm-5.0.0-9b441067c9-fb013845b4.zip/node_modules/resolve-url-loader/index.js!../../.yarn/__virtual__/postcss-loader-virtual-e7d8d6af87/0/cache/postcss-loader-npm-8.1.1-20cf547c92-7ae38e6351.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[3]!../../.yarn/__virtual__/sass-loader-virtual-3bd3db2d6c/0/cache/sass-loader-npm-16.0.3-70aa568612-cd49635013.zip/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].use[4]!./fonts.scss";
       export default content && content.locals ? content.locals : undefined;
